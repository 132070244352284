import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Scrollbar from "./components/Scrollbar";
import Footer from "./components/Footer";
import { AnimesProvider } from "./hooks/animes";
import { AuthProvider } from "./hooks/auth";
import { SearchProvider } from "./hooks/search";
import { SettingsProvider } from "./hooks/settings";

const IndexPage = React.lazy(() => import("./pages/IndexPage"));
const AnimePlayerPage = React.lazy(() => import("./pages/AnimePlayerPage"));
const DiscordCallbackPage = React.lazy(
  () => import("./pages/DiscordCallbackPage")
);
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const WatchTogetherPage = React.lazy(() => import("./pages/WatchTogetherPage"));

const routes = [
  { path: "/", Component: IndexPage },
  { path: "/watch/:id(\\d+)/:episode?", Component: AnimePlayerPage },
  { path: "/discord/callback", Component: DiscordCallbackPage },
  { path: "/login", Component: LoginPage },
  { path: "/watch-together/:party", Component: WatchTogetherPage },
];

const App = () => {
  const userID = localStorage.getItem("userID");

  return (
    <>
      <Helmet>
        <title>Aniplix</title>
      </Helmet>
      <Router>
        <Switch>
          <AuthProvider userID={userID ? BigInt(userID) : null}>
            <SettingsProvider>
              <AnimesProvider>
                <SearchProvider>
                  {routes.map(({ path, Component }) => (
                    <Route exact key={path} path={path}>
                      <Scrollbar>
                        <div className="flex flex-col bg-aniplix-dark min-w-full min-h-full">
                          <NavBar />

                          <div className="flex-grow max-w-7xl p-6 md:p-8 mx-auto">
                            <Suspense fallback={<Loading />}>
                              <Component />
                            </Suspense>
                          </div>

                          <Footer />
                        </div>
                      </Scrollbar>
                    </Route>
                  ))}
                </SearchProvider>
              </AnimesProvider>
            </SettingsProvider>
          </AuthProvider>
        </Switch>
      </Router>
    </>
  );
};

export default App;
