import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type AuthContextType = {
  userID: BigInt | null;
  setUserID: (userID: BigInt | null) => void;
};

type AuthProviderProps = {
  userID: BigInt | null;
  children: ReactNode;
};

const AuthContext = createContext<AuthContextType>(undefined!);

export const AuthProvider = (props: AuthProviderProps) => {
  const [userID, setUserID] = useState<BigInt | null>(props.userID);

  useEffect(() => {
    if (userID) {
      localStorage.setItem("userID", userID.toString());
    }
  }, [userID]);

  return (
    <AuthContext.Provider value={{ userID, setUserID }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
