import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API || "https://api.aniplix.xyz";
const SEARCH_API_BASE_URL =
  process.env.REACT_APP_SEARCH_API || "https://search.aniplix.xyz";

const OAUTH_URL =
  process.env.REACT_APP_AUTH_URL ||
  "https://discord.com/api/oauth2/authorize?client_id=856343715061039134&redirect_uri=https%3A%2F%2Faniplix.xyz%2Fdiscord%2Fcallback&response_type=code&scope=identify";

export const WS_WATCH_TOGETHER_URL =
  process.env.REACT_APP_WS_WATCH_TOGETHER_API ||
  "wss://api.aniplix.xyz/v1/animes/watch-together/ws";

const ApiV1Client = axios.create({
  baseURL: API_BASE_URL + "/v1",
  withCredentials: true,
});

const ApiV2Client = axios.create({
  baseURL: API_BASE_URL + "/v2",
  withCredentials: true,
});

const SearchClient = axios.create({
  baseURL: SEARCH_API_BASE_URL,
  headers: { "X-Meili-API-Key": process.env.REACT_APP_SEARCH_API_KEY },
});

ApiV1Client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      loginRedirect(window.location.pathname);
    }
    return Promise.reject(error);
  }
);

export const loginRedirect = (redirect?: string) => {
  const oauthUrl = new URL(OAUTH_URL);

  if (redirect) {
    const state = btoa(JSON.stringify({ redirect }));
    oauthUrl.searchParams.set("state", state);
  }

  window.location.href = oauthUrl.toString();
};

export const discordRedirect = () => {
  window.location.href = "https://discord.com/invite/Q8B6W6wPhT";
};

export enum WebSocketEvent {
  play = "PLAY",
  pause = "PAUSE",

  bufferStart = "BUFFER_START",
  bufferEnd = "BUFFER_END",

  durationUpdate = "DURATION_UPDATE",
  episodeUpdate = "EPISODE_UPDATE",
}

export type Anime = {
  id: number;
  title: string;
  author: string;
  studio: string;
  description: string;
  release_date: string;
  cover_url: string;
  episodes: number[];
};

export type AnimeEpisode = {
  number: number;
  content_url: string;
};

export type PartialAnime = {
  id: number;
  title: string;
};

export type LocalWatchHistory = {
  [anime: string]: {
    [episode: string]: { durationWatched: number; completed: boolean };
  };
};

export type ApiWatchHistory = {
  episode: number;
  durationWatched: number;
  completed: boolean;
}[];

export type PartyData = {
  animeID: number;
  episode: number;
  duration: number | null;
};

export async function getAnimes(id?: number): Promise<Anime[]> {
  const resp = await ApiV2Client.get("animes", { params: { id } });
  return resp.data as Anime[];
}

export async function getAnimeEpisodes(
  animeID: number,
  episode?: number
): Promise<AnimeEpisode[]> {
  const resp = await ApiV2Client.get("animes", {
    params: { id: animeID, episode },
  });
  return resp.data as AnimeEpisode[];
}

export async function searchAnimes(q: string): Promise<PartialAnime[]> {
  const resp = await SearchClient.get("indexes/animes/search", {
    params: { q, limit: 24 },
  });
  return resp.data.hits as PartialAnime[];
}

export async function discordCallback(code: string): Promise<BigInt> {
  const resp = await ApiV1Client.post(
    "auth/callback",
    {},
    { params: { code } }
  );
  return BigInt(resp.data.user_id);
}

export async function syncWatchHistoryEntry(
  animeID: number,
  episode: number,
  durationWatched: number,
  completed: boolean
): Promise<boolean> {
  const resp = await ApiV1Client.post(
    "animes/watch-history/",
    {},
    {
      params: {
        anime_id: animeID,
        episode,
        duration_watched: durationWatched,
        completed,
      },
    }
  );
  return resp.data.success;
}

export async function syncWatchHistory(
  watchHistory: LocalWatchHistory
): Promise<void> {
  for (const [animeString, episodes] of Object.entries(watchHistory)) {
    const anime = parseInt(animeString);
    const apiWatchHistory = await getWatchHistory(anime);

    for (const [episodeString, metadata] of Object.entries(episodes)) {
      const episode = parseInt(episodeString);
      const apiWatchHistoryEntry = apiWatchHistory.find(
        (entry) => entry.episode === episode
      );

      if (
        !apiWatchHistoryEntry ||
        apiWatchHistoryEntry.durationWatched < metadata.durationWatched
      ) {
        await syncWatchHistoryEntry(
          anime,
          episode,
          metadata.durationWatched,
          metadata.completed
        );
      }
    }
  }
}

export async function getWatchHistory(
  animeID: number,
  episode?: number
): Promise<ApiWatchHistory> {
  const resp = await ApiV1Client.get("animes/watch-history/", {
    params: { anime_id: animeID, episode },
  });

  return resp.data.map((watchHistoryEntry: any) => ({
    completed: watchHistoryEntry.completed,
    durationWatched: watchHistoryEntry.duration_watched,
    episode: watchHistoryEntry.episode,
  }));
}

export async function getPartyData(party: string): Promise<PartyData> {
  const resp = await ApiV1Client.get("animes/watch-together/", {
    params: { party: party },
  });

  return {
    animeID: resp.data.anime_id,
    episode: resp.data.episode,
    duration: resp.data.duration,
  };
}

export async function createParty(
  animeID: number,
  episode: number
): Promise<string> {
  const resp = await ApiV1Client.post(
    "animes/watch-together/",
    {},
    { params: { anime_id: animeID, episode } }
  );

  return resp.data.party;
}
