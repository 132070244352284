import clsx from "clsx";

type LoadingProps = {
  player?: boolean;
};

const Loading = (props: LoadingProps) => (
  <div className={clsx({ "absolute w-full h-full": props.player })}>
    <div
      className={clsx(
        "flex justify-center items-center pointer-events-none text-white w-full h-full",
        { "min-h-80vh": !props.player }
      )}
    >
      <svg
        className="animate-spin h-16 w-16"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-50"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  </div>
);

export default Loading;
