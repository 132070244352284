import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "./auth";
import { syncWatchHistory } from "../api";

type SettingsContextType = {
  sync: boolean;
  initialLogin: boolean;

  setSync: (sync: boolean) => void;
  setInitialLogin: (initialLogin: boolean) => void;
};

type SettingsProviderProps = {
  children: ReactNode;
};

const SettingsContext = createContext<SettingsContextType>(undefined!);

const DEFAULT_SETTINGS = {
  sync: false,
  initialLogin: true,
};

export const SettingsProvider = (props: SettingsProviderProps) => {
  const [sync, setSync] = useState<boolean>(DEFAULT_SETTINGS.sync);
  const [initialLogin, setInitialLogin] = useState<boolean>(
    DEFAULT_SETTINGS.initialLogin
  );

  const { userID } = useAuth();

  useEffect(() => {
    if (userID !== null) {
      const settings = localStorage.getItem("settings");

      if (settings === null) {
        localStorage.setItem(
          "settings",
          JSON.stringify({
            [userID.toString()]: DEFAULT_SETTINGS,
          })
        );
      } else {
        const settingsObj = JSON.parse(settings);
        const userIDString = userID.toString();

        setSync(settingsObj[userIDString].sync);
        setInitialLogin(settingsObj[userIDString].initialLogin);
      }
    }
  }, [userID]);

  useEffect(() => {
    if (userID !== null) {
      const settings = JSON.parse(localStorage.getItem("settings")!);

      settings[userID.toString()] = { sync, initialLogin };
      localStorage.setItem("settings", JSON.stringify(settings));

      if (initialLogin && sync) {
        syncWatchHistory(
          JSON.parse(localStorage.getItem("animesWatched") || "{}")
        );
        setInitialLogin(false);
      }
    }
  }, [userID, sync, initialLogin]);

  return (
    <SettingsContext.Provider
      value={{ sync, initialLogin, setSync, setInitialLogin }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
