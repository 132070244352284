import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { Anime, getAnimes } from "../api";

type AnimesContextType = Anime[];

type AnimesProviderProps = {
  children: ReactNode;
};

const AnimesContext = createContext<AnimesContextType>(undefined!);

export const AnimesProvider = (props: AnimesProviderProps) => {
  const [animes, setAnimes] = useState<Anime[]>([]);

  useEffect(() => {
    const fetchAnimes = async () => {
      setAnimes(await getAnimes());
    };
    fetchAnimes();
  }, []);

  return (
    <AnimesContext.Provider value={animes}>
      {props.children}
    </AnimesContext.Provider>
  );
};

export const useAnimes = () => useContext(AnimesContext);
