import { FormEvent } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { SearchIcon } from "@heroicons/react/solid";

import { useAuth } from "../hooks/auth";
import { useSearch } from "../hooks/search";
import logo from "../logo.png";
import clyde from "../clyde.svg";

const NavBar = () => {
  const { userID } = useAuth();
  const { search, query, setQuery } = useSearch();

  const isScreenMd = useMediaQuery({ query: "(min-width: 768px)" });

  const callSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    search();
  };

  return (
    <nav className="bg-aniplix-darkgray">
      <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8">
        <div className="flex items-center h-16 mx-1">
          <Link className="flex-none" to="/">
            <img
              className="block md:hidden w-10 h-10 rounded"
              src={logo}
              alt="Workflow"
            />
            <h1 className="hidden md:inline-block text-2xl text-white font-bold bg-aniplix-red rounded px-2 py-1">
              Aniplix
            </h1>
          </Link>

          <div className="flex-grow" />
          <form className="relative mx-2" onSubmit={callSearch}>
            <input
              className="border-2 border-aniplix-yag bg-aniplix-gray h-10 pl-2 pr-8 rounded-lg focus:outline-none text-aniplix-white"
              type="search"
              placeholder="Search"
              onChange={(event) => setQuery(event.target.value)}
              value={query}
            />
            <button
              className="absolute top-0 right-0 m-2 text-aniplix-white focus:outline-none"
              type="submit"
            >
              <SearchIcon width={24} height={24} />
            </button>
          </form>
          <div className="flex-grow md:flex-none" />

          {userID === null ? (
            <Link className="flex-none" to="/login">
              <button className="flex items-center bg-discord-blurple rounded px-1.5 py-2.5 md:py-1">
                <img src={clyde} alt="Discord" width={28} />
                <h2 className="hidden md:block text-xl text-white font-bold ml-2">
                  Login
                </h2>
              </button>
            </Link>
          ) : isScreenMd ? null : (
            <div className="w-10" />
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
