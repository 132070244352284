import { discordRedirect } from "../api";
import discordLogo from "../discord.svg";

const Footer = () => (
  <footer className="bg-aniplix-darkgray">
    <div className="max-w-7xl mx-auto p-4 text-center space-y-2">
      <button
        onClick={discordRedirect}
        className="flex items-center text-white bg-discord-blurple rounded mx-auto mb-3 px-3 py-2"
      >
        <p className="font-bold mr-1">Join us on</p>
        <img className="h-4" src={discordLogo} alt="Discord" />
      </button>

      <p className="text-sm text-aniplix-aaa">
        Copyright &copy; {new Date().getFullYear()} Aniplix. All Rights
        Reserved.
      </p>
      <p className="text-xs text-aniplix-yag">
        Disclaimer: This site does not store any files on its server. All
        contents are provided by non-affiliated third parties.
      </p>
    </div>
  </footer>
);

export default Footer;
