import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useAnimes } from "./animes";
import { Anime, searchAnimes } from "../api";

type SearchContextType = {
  query: string;
  setQuery: (query: string) => void;
  results: Anime[];
  search: () => void;
};

type SearchProviderProps = {
  query?: string;
  children: ReactNode;
};

const SearchContext = createContext<SearchContextType>(undefined!);

export const SearchProvider = (props: SearchProviderProps) => {
  const animes = useAnimes();
  const [query, setQuery] = useState<string>(props.query || "");
  const [results, setResults] = useState<Anime[]>([]);

  const history = useHistory();
  const location = useLocation();

  const search = () => {
    if (query) {
      history.push(`/?search=${query}`);
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    setQuery(new URLSearchParams(location.search).get("search") || "");
  }, [location.search]);

  useEffect(() => {
    const fetchResults = async () => {
      if (animes) {
        const results = await searchAnimes(query);

        const resultIDs = results.map((result) => result.id);
        const animeResults = animes.filter((anime) =>
          resultIDs.includes(anime.id)
        );
        setResults(animeResults);
      }
    };
    fetchResults();
  }, [animes, query]);

  return (
    <SearchContext.Provider value={{ query, setQuery, search, results }}>
      {props.children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
