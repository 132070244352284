import { ReactNode } from "react";

import { Scrollbars } from "react-custom-scrollbars";

type ScrollbarProps = {
  children?: ReactNode;
};

const Scrollbar = (props: ScrollbarProps) => (
  <Scrollbars
    autoHide
    autoHideTimeout={1000}
    autoHideDuration={200}
    style={{ height: "100vh" }}
    renderThumbVertical={({ style, props }) => (
      <div className="bg-aniplix-lightgray rounded" style={style} {...props} />
    )}
  >
    {props.children}
  </Scrollbars>
);

export default Scrollbar;
